<template>
    <section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <h2>{{msg}}</h2>
                <div>   
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-10 offset-md-1">
                    <object class="pdfobject mx-3 my-3" :data="pdf" type="application/pdf">
                      alt : <a href="test.pdf">test.pdf</a>
                    </object>
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-7 offset-md-1"> 
                    <form class="form form-horizontal mt-3 mb-3 ml-3">
                        <div class="form-group row">
                            <label class="col-sm-2 mt-2 form-control-label">Email</label>
                            <div class="col-sm-10">                            
                            <input type="text" class="col-sm-12 form-control form-control-success" v-model="emailProv"> 
                            </div>
                        </div>
                    </form>                        
                </div>
                <div class="col-md-2">
                    <b-btn class="float-right mt-3 mx-4" variant="primary" @click="enviarSolicitud">Enviar Email</b-btn>
                </div>

                <div class="col-md-2">
                    <b-btn class="float-right mt-3 mx-4" variant="secondary" @click="regresar">Regresar</b-btn>
                </div>                
            </div>

        </div>
    </section>            
</template>

<script>
import axios from 'axios';
export default {
    mounted() {
      this.subirPdf();           
    },
    data() {
      return {
          id: this.$route.params.id,          
          emailProv: this.$route.params.emailProv,
          folio: this.$route.params.folio,
          msg: 'SOLICITUD',
          pdf: 'data:application/pdf;base64,' + this.$route.params.pdf,
          userEmail: this.$store.getters.userEmail,
      }
    },
    methods:{
        subirPdf(name) {
            var d = new Date();
            var fecha = d.toISOString().split("T")[0] + " " + d.toLocaleTimeString().replace(/:/g,"").padStart(6,"0");
            var nameFile = "solicitud " + fecha + ".pdf";
            var datos = {"tipo": 'DOC',"nameFile": nameFile, "emailUser": this.userEmail, "idIncidencia": this.id,
                "avatar": this.pdf};
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/documentos/upload", datos)
            .then((response) => {             
                
            }, (error) => {
                console.log("Error: " + JSON.stringify( error.response.data.message) );
            })                
            },
        regresar(){
            this.$router.replace({ path: '/portal/incidencias' });
        },
        enviarSolicitud(){
            var solic = {
                "id": this.id, 
                "nameProv": this.$route.params.nameProv, 
                "emailProv": this.emailProv, 
                "emailUser": this.$store.getters.userEmail, 
                "pdf": this.pdf}; 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/enviarsolicitud", solic)
            .then((response) => {                      
                var salio = response.data;
                if(salio === "OK"){
                    this.regresar();
                }else{
                    alert("La solicitud no se pudo enviar por email, intente mas tarde.");
                }                
            }, (error) => {          

            })
        
        }
    }
}
</script>

<style>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}
    object.pdfobject{width: 100%; height: 35em;}
</style>
